import { Component } from '@angular/core';
import { EdgeShellConfig } from '@xpo-ltl/ngx-shell';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  shellConfig: EdgeShellConfig = {
    appTitle: 'OPS',
    description: 'CORE SAMPLE',
    edge: {
      shiftSelection: false,
      sicSelection: false,
    },
    menuEntries: [
      {
        label: 'Customs',
        path: '',
        children: [
          {
            label: 'US/Canada Conveyance Tool',
            path: 'conveyance-tool',
          },
          {
            label: 'Misc Customs Tools',
            path: 'tools',
          },
        ],
      },
    ],
  };
}
