import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { XpoLtlShellMenuComponent } from '@xpo-ltl/ngx-shell';
import { xpoConfigManagerProvider } from '@xpo-ltl/config-manager';
import { xpoAuthConfigManagerConfiguration } from '@xpo-ltl/ngx-auth';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    XpoLtlShellMenuComponent,
    BrowserAnimationsModule,
  ],
  providers: [
    xpoConfigManagerProvider(
      xpoAuthConfigManagerConfiguration({
        firebaseOptions: {
          apiKey: 'AIzaSyBIo-Gnt7bOdJLouRxWEeDzmBkI-h90JjY',
          authDomain: 'xpo-ltl-dev-cf3b6.firebaseapp.com',
          projectId: 'xpo-ltl-dev',
          appId: '1:339239167082:web:bc6eeb7ad303959bf29613',
        },
        configFileUrl: '../assets/config.json',
      })
    ),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
